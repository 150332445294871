import React from 'react';

export default function availability(props) {
    if(props.status === true){
        return (
            <div className="availabilityBoxStyle available">
                <p className="availableStyle">I'm availaible for freelance missions.</p>
            </div>
        )
    } else{
        return (
            <div className="availabilityBoxStyle unavailable">
                <p className="availabilityStyle">I'm currently unavailaible for freelance missions. 
                <br /> 
                Feel free to leave me an email for inquiries : <a className="link-anim" alt="mail" target="Work inquiries" title="Email - contact@lennythieuleux.com" href="mailto:contact@lennythieuleux.com">contact@lennythieuleux.com</a>
                </p>
            </div>
        )
    }
    
}
