import React from 'react';
import { Link, graphql } from "gatsby";
import Availability from '../components/availability';
import ScrollDownTitle from '../components/scrollDownTitle';

import Layout from "../components/layout";
import SEO from "../components/seo";

  export default function WorkPage({data}) {
  
    return (
      <Layout>
      <SEO title="Work" />
      <div className="WorkPage">
        <div>
            <h2 className="d-none">Work</h2>
                <div className="work-page-content">
                    <div id="work-projects-container">
                    <div className='availability'>
                        <Availability status={data.prismicUser.data.freelance_availability}/>
                    </div>
                    <ScrollDownTitle title="Discover my work"/>
                    <div id="work-projects-output">
                    {data.allPrismicProject.edges.map(document => (
                        <section key={document.node.url}>
                          {document.node.data.details_accessible__ ?
                        <Link to={`/work${document.node.url}`}>
                            <article>
                                <div>
                                    <div className='project-name'>
                                        <h3>{document.node.data.client}</h3>
                                    </div>
                                    <div className='project-description'>
                                        <span>{document.node.data.title.text}</span>
                                    </div>
                                </div>
                                <div className='project-category'>
                                    <span className='tags'>{document.node.data.categorie}</span>
                                </div>
                            </article>
                        </Link>
                        :
                        <article className="disabled">
                            <div>
                                <div className='project-name'>
                                    <h3>{document.node.data.client}</h3>
                                </div>
                                <div className='project-description'>
                                    <span>{document.node.data.title.text}</span>
                                </div>
                            </div>
                            <div className='project-category'>
                                <span className='tags'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                                  </svg>
                                  {document.node.data.categorie}
                                </span>
                            </div>
                        </article>
                        }
                        </section>
                    ))}
                    </div>
                    <div className='container-center-work'>
                      <Link className="cta" to="/about">More about me</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </Layout>
    )
  }

export const pageQuery = graphql`
query IndexQueryandIndexQuery {
    allPrismicProject(sort: {fields: data___publishing_date, order: DESC}) {
        edges {
          node {
            data {
              categorie
              client
              cover_image {
                fluid {
                  src
                }
              }
              title {
                text
              }
              details_accessible__
            }
            url
            id
          }
        }
      }
  prismicUser {
    data {
      freelance_availability
    }
  }
}
`
